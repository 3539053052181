import React, { useState } from "react"

// Components
import SidebarLayout from "../../../layouts/SidebarLayout"
import Section from "../../../components/section/Section"
import Seo from "../../../components/seo"
import ContentNavigation from "../../../components/scrollspy"
// Libraries / Components for Preview
import PreviewBlock from "../../../components/preview"

import BreadcrumbRoundedElevatedBasicPreview from "../../../library/components/breadcrumb/react/_preview/rounded/elevated-basic"
import BreadcrumbRoundedElevatedIconPreview from "../../../library/components/breadcrumb/react/_preview/rounded/elevated-icon"
import BreadcrumbRoundedElevatedTextIconPreview from "../../../library/components/breadcrumb/react/_preview/rounded/elevated-text_icon"

import BreadcrumbPlainFlatBasicPreview from "../../../library/components/breadcrumb/react/_preview/plain/flat-basic"
import BreadcrumbPlainFlatIconPreview from "../../../library/components/breadcrumb/react/_preview/plain/flat-icon"
import BreadcrumbPlainFlatTextIconPreview from "../../../library/components/breadcrumb/react/_preview/plain/flat-text_icon"

import HeroBreadCrumbComponent from "../../../library/components/breadcrumb/react/_preview/heroBreadcrumbComponent"

// *** Breadcrumbs ***//
// Plain
const BreadcrumbRoundedElevatedBasicJsx = require("!!raw-loader!../../../library/components/breadcrumb/react/rounded/elevated-basic.jsx")
const BreadcrumbRoundedElevatedBasicHTML = require("!!raw-loader!../../../library/components/breadcrumb/html/rounded/elevated-basic.html")

const BreadcrumbRoundedElevatedIconJsx = require("!!raw-loader!../../../library/components/breadcrumb/react/rounded/elevated-icon.jsx")
const BreadcrumbRoundedElevatedIconHTML = require("!!raw-loader!../../../library/components/breadcrumb/html/rounded/elevated-icon.html")

const BreadcrumbRoundedElevatedTextIconJsx = require("!!raw-loader!../../../library/components/breadcrumb/react/rounded/elevated-text_icon.jsx")
const BreadcrumbRoundedElevatedTextIconHTML = require("!!raw-loader!../../../library/components/breadcrumb/html/rounded/elevated-text_icon.html")

// Rounded
const BreadcrumbPlainFlatBasicJsx = require("!!raw-loader!../../../library/components/breadcrumb/react/plain/flat-basic.jsx")
const BreadcrumbPlainFlatBasicHTML = require("!!raw-loader!../../../library/components/breadcrumb/html/plain/flat-basic.html")

const BreadcrumbPlainFlatIconJsx = require("!!raw-loader!../../../library/components/breadcrumb/react/plain/flat-icon.jsx")
const BreadcrumbPlainFlatIconHTML = require("!!raw-loader!../../../library/components/breadcrumb/html/plain/flat-icon.html")

const BreadcrumbPlainFlatTextIconJsx = require("!!raw-loader!../../../library/components/breadcrumb/react/plain/flat-text_icon.jsx")
const BreadcrumbPlainFlatTextIconHTML = require("!!raw-loader!../../../library/components/breadcrumb/html/plain/flat-text_icon.html")

const BreadcrumbsPage = () => {
  const [activeTabs, setActiveTabs] = useState([
    {
      component_name: "BreadcrumbRoundedElevatedBasic",
      title: "Elevated breadcrumbs",
      active_tab: 1,
    },
    {
      component_name: "BreadcrumbRoundedElevatedIcon",
      title: "Elevated, leading icon",
      active_tab: 1,
    },
    {
      component_name: "BreadcrumbRoundedElevatedTextIcon",
      title: "Elevated, leading icon & text",
      active_tab: 1,
    },
    {
      component_name: "BreadcrumbPlainFlatBasic",
      title: "Flat",
      active_tab: 1,
    },
    {
      component_name: "BreadcrumbPlainFlatIcon",
      title: "Flat, leading icon",
      active_tab: 1,
    },
    {
      component_name: "BreadcrumbPlainFlatTextIcon",
      title: "Flat, leading icon & text",
      active_tab: 1,
    },
  ])

  const getActiveTabs = (id, tab) => {
    let updatedActiveTabs = activeTabs.map(activeTab => {
      if (activeTab.component_name === id) {
        return { ...activeTab, active_tab: tab }
      }
      return activeTab
    })
    setActiveTabs(updatedActiveTabs)
  }

  return (
    <SidebarLayout>
      <Seo
        title="Tailwind CSS Breadcrumbs - WindUI Component Library"
        ogtitle="Tailwind CSS Breadcrumbs - WindUI Component Library"
        description="Breadcrumb components consist of a list of links that visualize a page's location and allow navigation in a website. Built with Tailwind CSS by WindUI."
        ogdescription="Breadcrumb components consist of a list of links that visualize a page's location and allow navigation in a website. Built with Tailwind CSS by WindUI."
        url="components/breadcrumbs/"
        author="Wind UI"
        keywords="Tailwind CSS, Tailwind CSS Breadcrumbs, Breadcrumbs, Breadcrumb Components, WindUI, windui, wind ui"
        robots="index, follow"
        revisit_after="7 days"
      />
      <div className="min-w-0 flex-1">
        {/* Hero Section */}
        <Section ariaLabelledby="intro-heading" id="intro">
          <h1 id="intro-heading">Breadcrumbs</h1>
          <p>
            Breadcrumbs consist of a list of links that help a user visualize a
            page's location within the hierarchical structure of a website, and
            allow navigation up to any of its "ancestors".
          </p>
          <div className="flex w-full items-center justify-center rounded-xl bg-wuiSlate-50 p-16 px-5 shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200">
            <section
              className="not-prose max-w-full"
              aria-multiselectable="false"
            >
              <HeroBreadCrumbComponent />
            </section>
          </div>
        </Section>

        {/* Variations Section*/}
        <Section ariaLabelledby="variations-heading" id="variations-section">
          <h2 id="variations-heading">Variations</h2>

          {/* BreadCrumbs Elevated Basic Section */}
          <h3 id="BreadcrumbRoundedElevatedBasic">Elevated Breadcrumbs</h3>

          <PreviewBlock
            id="BreadcrumbRoundedElevatedBasic"
            HtmlComponent={BreadcrumbRoundedElevatedBasicHTML.default}
            JsxComponent={BreadcrumbRoundedElevatedBasicJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-wuiSlate-50 p-16">
              <BreadcrumbRoundedElevatedBasicPreview
                BreadcrumbRoundedElevatedBasic={
                  activeTabs[0].active_tab === 1
                    ? BreadcrumbRoundedElevatedBasicHTML.default
                    : BreadcrumbRoundedElevatedBasicJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/*  Elevated Breadcrumbs with Leading Icon */}
          <h3 id="BreadcrumbRoundedElevatedIcon">
            Elevated Breadcrumbs with Leading Icon
          </h3>

          <PreviewBlock
            id="BreadcrumbRoundedElevatedIcon"
            HtmlComponent={BreadcrumbRoundedElevatedIconHTML.default}
            JsxComponent={BreadcrumbRoundedElevatedIconJsx.default}
            getActiveTabs={getActiveTabs}
            extraClassesResizableBox=""
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-wuiSlate-50 p-16">
              <BreadcrumbRoundedElevatedIconPreview
                BreadcrumbRoundedElevatedIcon={
                  activeTabs[1].active_tab === 1
                    ? BreadcrumbRoundedElevatedIconHTML.default
                    : BreadcrumbRoundedElevatedIconJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* BreadCrumbs Elevated Text Icon Section */}
          <h3 id="BreadcrumbRoundedElevatedTextIcon">
            Elevated Breadcrumbs with Leading Icon & Text
          </h3>

          <PreviewBlock
            id="BreadcrumbRoundedElevatedTextIcon"
            HtmlComponent={BreadcrumbRoundedElevatedTextIconHTML.default}
            JsxComponent={BreadcrumbRoundedElevatedTextIconJsx.default}
            getActiveTabs={getActiveTabs}
            extraClassesResizableBox=""
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-wuiSlate-50 p-16 ">
              <BreadcrumbRoundedElevatedTextIconPreview
                BreadcrumbRoundedElevatedTextIcon={
                  activeTabs[2].active_tab === 1
                    ? BreadcrumbRoundedElevatedTextIconHTML.default
                    : BreadcrumbRoundedElevatedTextIconJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* BreadCrumbs Flat Normal Section */}
          <h3 id="BreadcrumbPlainFlatBasic">Flat Breadcrumbs</h3>

          <PreviewBlock
            id="BreadcrumbPlainFlatBasic"
            HtmlComponent={BreadcrumbPlainFlatBasicHTML.default}
            JsxComponent={BreadcrumbPlainFlatBasicJsx.default}
            getActiveTabs={getActiveTabs}
            extraClassesResizableBox=""
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 ">
              <BreadcrumbPlainFlatBasicPreview
                BreadcrumbPlainFlatBasic={
                  activeTabs[3].active_tab === 1
                    ? BreadcrumbPlainFlatBasicHTML.default
                    : BreadcrumbPlainFlatBasicJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* BreadCrumbs Flat Icon Section */}
          <h3 id="BreadcrumbPlainFlatIcon">
            Flat Breadcrumbs with Leading Icon
          </h3>

          <PreviewBlock
            id="BreadcrumbPlainFlatIcon"
            HtmlComponent={BreadcrumbPlainFlatIconHTML.default}
            JsxComponent={BreadcrumbPlainFlatIconJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 ">
              <BreadcrumbPlainFlatIconPreview
                BreadcrumbPlainFlatIcon={
                  activeTabs[4].active_tab === 1
                    ? BreadcrumbPlainFlatIconHTML.default
                    : BreadcrumbPlainFlatIconJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* BreadCrumbs Flat Text Icon Section */}
          <h3 id="BreadcrumbPlainFlatTextIcon">
            Flat Breadcrumbs with Leading Icon & Text
          </h3>

          <PreviewBlock
            id="BreadcrumbPlainFlatTextIcon"
            HtmlComponent={BreadcrumbPlainFlatTextIconHTML.default}
            JsxComponent={BreadcrumbPlainFlatTextIconJsx.default}
            getActiveTabs={getActiveTabs}
            extraClassesResizableBox=""
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 ">
              <BreadcrumbPlainFlatTextIconPreview
                BreadcrumbPlainFlatTextIcon={
                  activeTabs[5].active_tab === 1
                    ? BreadcrumbPlainFlatTextIconHTML.default
                    : BreadcrumbPlainFlatTextIconJsx.default
                }
              />
            </div>
          </PreviewBlock>
        </Section>

        {/* Usage Section*/}
        <Section ariaLabelledby="usage-heading" id="usage-section">
          <h2 id="usage-heading">Usage</h2>
          <p>
            Breadcrumbs consist of a list of links that help a user visualize a
            page's location within the hierarchical structure of a website, and
            allow navigation up to any of its "ancestors". On small screens only
            the last two elements are available. Links length is limited to 20
            characters
          </p>
        </Section>

        {/* Specs Section */}
        <Section ariaLabelledby="specs-heading" id="specs">
          <h2 id="specs-heading">Specs</h2>
          <p>
            Breadcrumbs come in 2 styles (elevated and flat). Text overflow
            ellipsis is used to prevent overflow on large texts. On smaller
            screens, only the current and the previous items are shown (to save
            screen real estate).
          </p>
          <ul>
            <li>
              <strong>elevated:</strong> Elevated look with 3.5rem height, 1rem
              horizontal padding and 0.375rem (6px) border radius and drop
              shadow (shadow-md)
            </li>
            <li>
              <strong>flat:</strong> No background or shadow, keeping the height
              the same as the line height 1.5rem (24px), better fit for minimal
              designs
            </li>
          </ul>
        </Section>

        {/* Accessibility Notes Section */}
        <Section
          ariaLabelledby="accessibility-notes-heading"
          id="accessibility-notes"
        >
          <h2 id="accessibility-notes-heading">Accessibility notes</h2>
          <p>
            Breadcrumbs are implemented with a <code>&lt;nav&gt;</code> HTML tag
            to apply semantic meaning to its use. The markup includes an{" "}
            <code>aria-label="breadcrumbs"</code> to describe the type of
            navigation. The <code> aria-current="page"</code> role is used for
            the last element that points to the current page. This will tell
            assistive technology (AT) devices that the focused link is pointing
            to the current page.
          </p>
        </Section>
      </div>

      <ContentNavigation variations={activeTabs} />
    </SidebarLayout>
  )
}

export default BreadcrumbsPage
